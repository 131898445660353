const ACCOUNT_TYPE_INDIVIDUAL = 'individual';

const ORGANIZATION_SUBSCRIPTION_ESSENTIAL = 'essential';
const ORGANIZATION_SUBSCRIPTION_BUSINESS = 'business';
const ORGANIZATION_SUBSCRIPTION_ENTERPRISE = 'enterprise';

// authentication mode setting for each organization
const AUTHENTICATION_MODE_CONNECT = 'connect'; // aka 'external', managed by the organization's own website (for organizations offering saas/membership products on a separate website and handling payments via unicorn springs; could also be for regular products if there's another website handling the user sign up / sign in, catalog, etc. and just using unicorn springs for secure payments and account management) and client application will declare the user and account that are arriving when they use the connect API
const AUTHENTICATION_MODE_DELEGATE = 'delegate'; // aka 'internal', managed by unicorn springs
const AUTHENTICATION_MODE_SSO = 'sso'; // managed by external SSO that both client application website and unicorn springs can share

const AUTHENTICATION_MODE_LIST = [
    AUTHENTICATION_MODE_CONNECT,
    AUTHENTICATION_MODE_DELEGATE,
    AUTHENTICATION_MODE_SSO,
];

export {
    ACCOUNT_TYPE_INDIVIDUAL,
    ORGANIZATION_SUBSCRIPTION_ESSENTIAL,
    ORGANIZATION_SUBSCRIPTION_BUSINESS,
    ORGANIZATION_SUBSCRIPTION_ENTERPRISE,
    AUTHENTICATION_MODE_CONNECT,
    AUTHENTICATION_MODE_DELEGATE,
    AUTHENTICATION_MODE_SSO,
    AUTHENTICATION_MODE_LIST,
};

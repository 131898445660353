<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Organization overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="organization">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert dense type="warning" v-if="!organizationSummary.brandprofile">
                        <router-link :to="{ name: 'organization-edit-brandprofile', params: { organizationId: this.$route.params.organizationId } }" class="white--text">Set up a BrandProfile</router-link> to customize your storefront.
                    </v-alert>
                    <v-alert dense type="warning" v-if="organization.is_production && !organizationSummary.stripe_account_id">
                        <router-link :to="{ name: 'organization-edit-stripe', params: { organizationId: this.$route.params.organizationId } }" class="white--text">Set up a Stripe account</router-link> to accept payments.
                    </v-alert>
                    <v-card>
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Organization settings</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-2">Organization name</p>
                            <p class="mb-0 pb-0">
                                <EditableText :value="organizationName" @input="editOrganizationName" dense/>
                            </p>

                            <p class="text-overline mb-0 mt-2">Unicorn Springs Subscription</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-subscription', params: { organizationId: this.$route.params.organizationId } }">{{ organizationSubscriptionDisplay }}</router-link>
                            </p>

                            <p class="text-overline mb-0 mt-8">API Tokens</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-client', params: { organizationId: this.$route.params.organizationId } }">See all clients</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.client_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-client-token', params: { organizationId: this.$route.params.organizationId } }">See all API tokens</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.client_token_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-8">Web Hooks</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-webhook-receiver', params: { organizationId: this.$route.params.organizationId } }">See all webhook receivers</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.webhook_receiver_active_count }} active)</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-webhook-key', params: { organizationId: this.$route.params.organizationId } }">See all webhook keys</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.webhook_key_count }} current)</span>
                            </p>
                            <!-- TODO: this should be in an "advanced" view -->
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-webhook-message', params: { organizationId: this.$route.params.organizationId } }">See all webhook messages</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.webhook_message_recent_count }} last 30 days)</span>
                            </p>

                            <p class="text-overline mb-0 mt-8">Stripe</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-stripe', params: { organizationId: this.$route.params.organizationId } }">Manage Stripe settings</router-link>
                            </p>

                            <!-- NOTE: this section will always be for service admin only; but some settings are available to customer as self-service via specialized activities, like the custom hostname -->
                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-8">Settings (Service Administrator ONLY) <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text text--darken-2"></font-awesome-icon></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-transaction-fee', params: { organizationId: this.$route.params.organizationId } }">Edit transaction fee</router-link>
                                <EditableTextSelect label="Production" :value="isProduction" :items="yesnoChoices" @input="editIsProduction" dense></EditableTextSelect>
                            </p>
                            </template>

                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"></font-awesome-icon></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="red--text">
                                    <font-awesome-icon :icon="['fas', 'trash']" class="mr-2"></font-awesome-icon>
                                    Delete organization
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0" style="font-size: 0.8em;">
                                    <router-link :to="{ name: 'organization-delete', params: { organizationId: this.$route.params.organizationId } }">How to delete this organization</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
import EditableTextSelect from '@/components/EditableTextSelect.vue';
// import UserList from '@/components/organization-dashboard/UserList.vue';

import {
    ORGANIZATION_SUBSCRIPTION_ESSENTIAL,
    ORGANIZATION_SUBSCRIPTION_BUSINESS,
    ORGANIZATION_SUBSCRIPTION_ENTERPRISE,
} from '@/sdk/constants';

export default {
    components: {
        EditableText,
        EditableTextSelect,
        // UserList,
    },
    data: () => ({
        // organization: null,
        organizationSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            organization: (state) => state.organization,
            focus: (state) => state.focus,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isProduction() {
            return this.organization?.is_production ?? true;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        organizationSubscriptionDisplay() {
            let displayText;
            switch (this.organization?.subscription) {
            case ORGANIZATION_SUBSCRIPTION_ESSENTIAL:
                displayText = 'Essential';
                break;
            case ORGANIZATION_SUBSCRIPTION_BUSINESS:
                displayText = 'Business';
                break;
            case ORGANIZATION_SUBSCRIPTION_ENTERPRISE:
                displayText = 'Enterprise';
                break;
            default:
                displayText = this.organization?.subscription;
                break;
            }
            return displayText;
        },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
    },
    watch: {
        focus() {
            this.loadOrganizationSummary();
        },
    },
    methods: {
        // async loadOrganization() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { loadOrganization: true });
        //         const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
        //         console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.organization = response;
        //         } else {
        //             // TODO: redirect back to organization list? show a not found message?
        //         }
        //     } catch (err) {
        //         console.error('failed to load organization', err);
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { loadOrganization: false });
        //     }
        // },
        async loadOrganizationSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganizationSummary: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.check({ item: 'summary' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organizationSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganizationSummary: false });
            }
        },
        async editOrganizationName(newValue) {
            try {
                this.$store.commit('loading', { editOrganizationName: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.edit({ name: newValue });
                console.log(`editOrganizationName: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    // this.$set(this.organization, 'name', newValue);
                    this.$store.dispatch('loadOrganization', { organizationId: this.$route.params.organizationId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit organization name' });
                }
            } catch (err) {
                console.error('failed to edit organization name', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit organization name' });
            } finally {
                this.$store.commit('loading', { editOrganizationName: false });
            }
        },
        async editIsProduction(newValue) {
            try {
                this.$store.commit('loading', { editIsProduction: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.edit({ is_production: newValue });
                console.log(`editIsProduction: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$store.dispatch('loadOrganization', { organizationId: this.$route.params.organizationId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit organization production mode' });
                }
            } catch (err) {
                console.error('failed to edit organization production mode', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit organization production mode' });
            } finally {
                this.$store.commit('loading', { editIsProduction: false });
            }
        },
    },
    mounted() {
        // this.loadOrganization();
        this.loadOrganizationSummary();
    },
};
</script>
